import React, {useEffect, useState} from 'react';
import {useNavigate, useMatch} from '@reach/router';
import { MATCH_NODE, MATCH_STORY } from '../../constants/match';
import styled from "styled-components";
//import Graph from '../components/Graph';
import loadable from '@loadable/component';
import '../../styles/global.css';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import update from 'immutability-helper';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Background from "../Background";
import Menu from "../Menu";
// wrapped in loadable as forcegraph contains window refs
const Graph = loadable(() => import('../Graph'));
const InnerContainer = styled.div``;
export default function Layout({data}) {
    const navigate = useNavigate();
    const story = useMatch(MATCH_STORY)?.story;
    const node = useMatch(MATCH_NODE)?.node;
    
    const [currentNode, setCurrentNode] = useState(-1);

    const [graphData, setGraphData] = useState(null);
    const [storyData, setStoryData] = useState([]);
    const [rootId, setRootId] = useState(null);
    const [activeTag, setActiveTag] = useState(null);
    const [currentBG, setCurrentBG] = useState(null);
       

    // Node slug update
    useEffect(()=>{
        const activeNodeId = graphData?.nodes.find(n=>n.slug === node)?.id;
        console.log("!!!NODE SLUG",node,activeNodeId,currentNode);
        // if(activeNodeId && currentNode!=activeNodeId)
        //     handleNodeChange(activeNodeId);
    }, [node]);

    useEffect(() => {
        console.log("TAG CHANGE", activeTag);

        activeTag && setGraphData({
            ...graphData,
            nodes: graphData.nodes.map((node, i) => {
                return ({
                    ...node,
                    collapsed: false,
                    isActive: node.tags.map(x => x.id).indexOf(activeTag)
                });
            })
        });
    }, [activeTag]);

    /*
    **  NODE INDEX CHANGE
    */
    const handleNodeChange = (nodeId, resetAll = false, openAncestors = false) => {
        console.debug('LAYOUT: HANDLE NODE CHANGE', nodeId);
        const ind = graphData?.nodes.map(item => item.id).indexOf(nodeId);
        let changedState;
        setActiveTag(null);
        // reset actives
        if (resetAll) {
            console.debug('LAYOUT: HANDLE NODE CHANGE (reset)');
            setGraphData({
                ...graphData,
                nodes: graphData.nodes.map((node, i) => {
                    if (node.id == rootId)
                        return ({
                            ...node,
                            collapsed: false,
                            isActive: true
                        });
                    return ({
                        ...node,
                        collapsed: true,
                        isActive: false
                    });
                })
            });
        } else if (openAncestors) {
            // get parent list
            
            const ancestorIds = findMyAncestors(graphData.nodes[ind]?.id);
            console.log('open ancestors',ancestorIds);
            setGraphData({
                ...graphData,
                nodes: graphData.nodes.map((node, i) => {
                    if (node.id==nodeId||ancestorIds.includes(node.id))
                        return ({
                            ...node,
                            collapsed: false,
                            isActive: true
                        });
                    return ({
                        ...node,
                        collapsed: true,
                        isActive: false
                    });
                })
            });
        } else {
            console.log('setting to active', graphData.nodes[ind]);
            console.log(currentNode);
            const currNodeIndex = graphData?.nodes.map(item => item.id).indexOf(currentNode);
            if (nodeId == -1 && currentNode != -1) {
                changedState = update(
                    graphData, {
                        nodes: { 
                            [currNodeIndex]: { 
                                //collapsed: { $apply: x => ind == 0 && story ? false : !x }, 
                                isActive: { $apply: x => false }, 
                                childLinks: [] 
                            } 
                        }
                    }
                );            
            } else {                        
                changedState = update(
                    graphData, {
                        nodes: { 
                            [ind]: { 
                                collapsed: { $apply: x => ind == 0 && story ? false : !x }, 
                                isActive: { $apply: x => true }, 
                                childLinks: [] 
                            } 
                        }
                    }
                );            
            }
            setGraphData(changedState);
        }
        // text content
        const node = graphData.nodes[ind];
        
        setCurrentNode(node?.id);
        //TODO: find ancestor bg if none exists
        if (nodeId != -1)
            setCurrentBG(findAncestorWithProp('background',node));

    };

    // return list of parent ids
    const findMyAncestors = (nodeId,gData=graphData.nodes,links=graphData.filteredLinks) => {
        let pIDs = [];
        // get the current parent
        const recurseFind = (curId)=>{
            // can sometimes be cross links so find all
            let parentIds = links.filter(x=>x.target == curId)?.map(x=>x.source);
            pIDs.push(...parentIds);
            if(!parentIds.includes(rootId))
                parentIds.forEach(x=>recurseFind(x));
        };
        recurseFind(nodeId);
        // remove dupes on return
        return [...new Set(pIDs)];
    }

    // recursivly search ancestors until property found
    const findAncestorWithProp = (prop,node,gData=graphData.nodes,links=graphData.filteredLinks)=>{
        if(!node)
            return null;
        // node already has prop so return
        if(node[prop]!=null)
            return node[prop];
        let recurseFind = (parentId)=>{
            let n = gData.find(x=>x.id==parentId);
            if(!n) {
                console.error("BROKEN PARENT RELATIONSHIP  parent:",parentId,"node:",node,links);
                return null;
            }
            if(n[prop])
                return n[prop];
            let pl = links.find(x=> x.target ==n.id);
            if(pl)
                return recurseFind(pl.source);
            else return null;
        }        
        const pLink = links.find(x=> x.target ==node.id);
        return recurseFind(pLink?.source);
    }

    const extractNodeVals = (obj, type, childObjects) => {
        let node = {
            id: obj.id,
            type,
            title: obj.title,
            content: obj.content,
            slug: obj.slug || obj.id,
            collapsed: true,
            childLinks: [],
            isActive: false,
            //fx:0,fy:0,fz:0,
            tags: obj.tags || [],
            //background:obj.background||`https://picsum.photos/1200/${Math.floor(Math.random() * (810 - 800) + 800)}`,
            background: getImage(obj.background),//obj.background?.childImageSharp?.fluid?.src || `https://picsum.photos/1200/800`,
            color: obj.color && JSON.parse(obj.color).css || null,
            content_images: obj.content_images || []
        };
        let links = [];
        if (childObjects) {
            for (const [key, value] of Object.entries(childObjects)) {
                value.forEach(x => {
                    let identifier = `${key}_${x.id}`;
                    links.push({ source: obj.id, target: identifier, value: x.value });
                    node.childLinks.push(identifier);
                });
            };
        }
        return { node, links };
    };
    
    /*
    **  BUILD THE SCHEMA
    */
    useEffect(() => {
        if (!data)
            return;
        // set initial to homepage bg
        setCurrentBG(getImage(data.strapiHomepage.background));

        let nodes = [];
        let links = [];

        data.allStrapiVision.nodes.forEach(vision => {
            setRootId(vision.id);
            let v = extractNodeVals(vision, "vision", { "Objective": vision.objectives });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiObjective.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "ambition",  { "Foundation": obj.foundations });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiFoundation.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "foundation", { "Initiative": obj.initiatives, "Target": obj.targets, "Callout": obj.callouts });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiInitiative.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "initiative");//, { "Beneficiary": obj.beneficiaries });
            nodes.push(v.node);
            //links.push(...v.links);
        });
        //removed beneficiaries for now
        data.allStrapiBeneficiary.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "beneficiary");
            nodes.push(v.node);
        });
        data.allStrapiCallout.nodes.forEach(obj => {
            nodes.push(extractNodeVals(obj, "callout").node);
        });
        data.allStrapiTarget.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "target", { "Measurable": obj.measurables });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiMeasurable.nodes.forEach(obj => {
            nodes.push(extractNodeVals(obj, "measurable").node);
        });
        let ids = nodes.map(o => o.id);
        // remove dupes
        let filtered = nodes.filter(({ id }, index) => !ids.includes(id, index + 1));
        // sanity check non existent relationships
        let filteredLinks = links;//links.filter(({ target }, index) => ids.includes(target));

        // try grab the missing child colors from ancestor
        nodes.filter(x=>x.color==null).forEach(x=>x.color=findAncestorWithProp('color',x,filtered,filteredLinks)||'grey');        

        setGraphData({ nodes: filtered, filteredLinks });

        // STORY DATA
        data?.allStrapiStory?.nodes?.forEach(x => {
            let snodes = [];
            // x.vision&&nodes.push(`Vision_${x.vision.id}`);
            // x.objective&&nodes.push(`Objective_${x.objective.id}`);
            // x.foundation&&nodes.push(`Foundation_${x.foundation.id}`);
            // x.callout&&nodes.push(`Callout_${x.callout.id}`);
            // x.target&&nodes.push(`Target_${x.target.id}`);
            // x.measurable&&nodes.push(`Measurable_${x.measurable.id}`);
            x.vision && snodes.push(nodes.find(y => y.id == `Vision_${x.vision.id}`));
            x.objective && snodes.push(nodes.find(y => y.id == `Objective_${x.objective.id}`));
            x.foundation && snodes.push(nodes.find(y => y.id == `Foundation_${x.foundation.id}`));
            x.callout && snodes.push(nodes.find(y => y.id == `Callout_${x.callout.id}`));
            x.target && snodes.push(nodes.find(y => y.id == `Target_${x.target.id}`));
            x.measurable && snodes.push(nodes.find(y => y.id == `Measurable_${x.measurable.id}`));
            // sanity check for non existent nodes
            x.nodes = snodes.filter(({ id }) => ids.includes(id));
        });
        setStoryData([...data.allStrapiStory.nodes]);
    }, [data]);


    useEffect(() => console.log(storyData), [storyData]);
    
    const handleStoryChange = (id) => {
        setActiveTag(null);
        const story = storyData.find(x => x.id == id);
        const nextNode = story?.nodes[0];
        console.debug("STORY CHANGE", id, story, nextNode);
        //setCurrentNode(nextNode);
        //setCurrentStory(id);
        
        // Get slug from story id
        story?.slug && navigate(`/story/${story?.slug}`);
    };

    const resetView = () => {        
        handleNodeChange(rootId,true);
    };

    const openAll = () => {
        setGraphData({
            ...graphData,
            nodes: graphData.nodes.map((node, i) => {
                return ({
                    ...node,
                    collapsed: false,
                    isActive: false
                });
            })
        });
    };

    /*
    **  RENDER
    */
    return graphData && (
        <InnerContainer>
            <Background currentBG={currentBG} />            
            <Graph
                handleNodeChange={handleNodeChange}
                rootId={rootId}
                graphData={graphData}
                setGraphData={setGraphData}
                storyData={storyData}
                activeTag={activeTag}
                curNode={currentNode}
            />
            <Header
                storyData={storyData}
                content={data.strapiHomepage}
            />
            <Sidebar
                storyData={storyData}
                graphData={graphData}
                setActiveTag={setActiveTag}
                handleNodeChange={handleNodeChange}
            />
            <Menu resetView={resetView} openAll={openAll} />
        </InnerContainer>  
    );
}
