import * as React from "react";
import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const BGContainer = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    opacity:1;
    overflow:hidden;
    & .gatsby-image-wrapper {
        width:100%;
        height:100%;
    }
    img {
        width:100%;
        height:100%;
        object-fit:cover;
    }
`;
//animation: ${props => props.show ? fadeIn : fadeOut} 1s ease-in forwards;

const Background = (props) => {
  const { currentBG } = props;
useEffect(()=>{console.log("BGG",currentBG)},[currentBG]);
  // RENDER
  return (
    <BGContainer show={1}>
      {currentBG&&<GatsbyImage id={"bg"} image={currentBG} />}
    </BGContainer>
  )
};

export default Background;