import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { Router } from '@reach/router';
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "@fontsource/roboto-condensed";
import "@fontsource/lato";
import '../styles/global.css';
import Layout from '../components/Layout';
import { login, isAuthenticated, getProfile } from "../utils/auth";

const Container = styled.div`
    background: #000;
    background-size:cover;
    transition:background .2s ease-in-out;
    background-image:url('${props => props.bgimage}');
    overflow:hidden;
`;
const GraphPage = (props) => {
    const data = useStaticQuery(graphql`
    query {
        strapiHomepage {
            title
            description
            callout
            background {
                childImageSharp {
                    gatsbyImageData(width: 1920, placeholder: BLURRED)
                }
            }
        }
        allStrapiVision {
            nodes {
            id
            strapiId
            title
            content
            color
            slug
            background {
                childImageSharp {
                    gatsbyImageData(width: 1920, placeholder: BLURRED)
                }
            }
            objectives {
                id
            }
            }
        }
        allStrapiObjective {
            nodes {
            id
            strapiId
            title
            content
            color
            slug
            background {
                childImageSharp {
                    gatsbyImageData(width: 1920, placeholder: BLURRED)
                }
            }
            foundations {
                id
            }
            }
        }
        allStrapiFoundation {
            nodes {
                id
                strapiId
                title
                content
                color
                slug
                initiatives {
                    id
                }
                targets {
                    id
                }
                callouts {
                    id
                }
            }
        }
        allStrapiInitiative {
            nodes {
                id
                strapiId
                title
                content
                slug
                beneficiaries {
                    id
                }                               
            }
        }
        allStrapiCallout {
            nodes {
                id
                strapiId
                title
                content
                slug
                tags {
                    name
                    id
                }
                content_images {                                            
                    base
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
        allStrapiBeneficiary {
            nodes {
                id
                strapiId
                title
                content
                slug
                initiatives {
                    id
                }
            }
        }                     
        allStrapiTarget {
            nodes {
                measurables {
                id
                }
                id
                title
                content
                slug
            }
        }
        allStrapiMeasurable {
            nodes {
                id
                strapiId
                title:Title
                content:BodyCopy  
                slug
                content_images: BodyCopy_images {                                            
                    base
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }                  
            }
        }
        allStrapiStory {
            nodes {
                strapiId
                title
                id
                vision {
                    id
                }
                objective {
                    id
                }
                foundation {
                    id
                }
                target {id}
                measurable {id}
                camera {
                    x
                    y
                    z
                }
                slug
            }
        }
    }
    `);
    // check logged in
    if (!isAuthenticated()) {
        login()
        return <p>Redirecting to login...</p>
    }
    return (
        <Container>
            <Helmet>
                <meta charSet="utf-8" />
                <title>DIGITAL ECONOMY</title>
                <link rel="shortcut icon" href="https://static.qgov.net.au/assets/v2/images/skin/favicon.ico" />
                <link rel="apple-touch-icon" href="https://static.qgov.net.au/assets/v2/images/skin/favicon.ico" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
            </Helmet>
            <Router basepath="/">            
                <Layout path={"*"} data={data} />
            </Router>
        </Container>
    )
}
export default GraphPage