import React, { useState, useEffect } from "react";

import { logout } from "../../utils/auth";

export default function Menu({ resetView, openAll }) {
    return (<div style={{ position: 'absolute', top: 60, left: '1em', background:'rgba(33,33,33,.3)', padding:'.25em', borderRadius:'.25em' }}>
        <a href="#logout"
            onClick={e => {
                logout()
                e.preventDefault()
            }}
        >
            log out
        </a>
        |
        <a href="#reset"
            onClick={e => {
                resetView && resetView()
                e.preventDefault()
            }}
        >
            reset view
        </a>
        | <a href="#openall"
            onClick={e => {
                openAll && openAll()
                e.preventDefault()
            }}
        >
            open all
        </a>
    </div>)
}